import $ from "jquery";
import http from "@/api/axios";
export default {
    name: "",
    components: {},
    data() {
        return {
            activeCase: false,
            type: 0,
            caseList: [{
                    subtitle: '咨询设计',
                    mainBody: `<p style="text-indent:40px"><span style="font-weight:bold">咨询概述：</span>步速者拥有一支经验丰富的团队，在行业、技术等方面有着深厚的优势，我们根据用户现有需求进行整理，结合用户战略规划进行总体评估，输出最佳的解决方案。</p>
          <p style="text-indent:40px"><span style="font-weight:bold">服务优势：</span>
          在行业和应用领域拥有丰富的技术经验广泛的业务积累，业务遍布全国31个省、直辖市、自治区，为客户提供优质满意的咨询服务；
          多行业真实案例，定制高品质解决方案，涉及30多个行业，尤其是在高端装备制造，交通运输业，教育，医疗，传媒，快消费，旅游等领域业绩突出；</p>`
                },
                {
                    subtitle: '系统实施',
                    mainBody: `<p style="text-indent:40px"><span style="font-weight:bold">描述：</span>步速者拥有标准的软件项目管理规范，连续两次通过了CMMI3的认证评估，规范的流程确保项目成功。</p>
          <p style="text-indent:40px"><span style="font-weight:bold">服务内容：</span>基于CMMI3标准规范，步速者积累了特有的软件项目实施规范，针对国内的相关特点，优化完善了软件管理实施规范；在相关技术积累上，也一直与时俱进，追随前沿科技，能力覆盖前后端分离、微服务、容器、大数据等多项技术领域，能够为客户的核心业务系统，提供多层次、全方位、快速、准确、有效的性能规划、评测、优化和管理服务。</p>
          <p style="text-indent:40px"><span style="font-weight:bold">服务优势：</span><p style="text-indent:40px">1、团队成员平均实施服务年限超过10年，拥有过硬的专业知识和服务精神，能力覆盖多个领域。</p>
          <p style="text-indent:40px">2、步速者拥有丰富的项目经验，积累了涉及高端装备制造，交通运输业，教育，医疗，传媒，快消费，旅游，互联网等领域的性能解决方案知识库。</p>
          <p style="text-indent:40px">3、统一过程管理，确保各工程阶段的标准化作业。</p>
          </p>
          `
                },
                {
                    subtitle: '敏捷运维',
                    mainBody: `<p style="text-indent:40px"><span style="font-weight:bold">服务描述：</span>步速者致力于为客户提供优质的敏捷运维服务，具有长期在各种服务器操作系统运维的经验，具有在linux下的k8s容器调度系统的管理经验，为各行业客户提供定制化的项目维护、优化及管理全生命周期服务。</p>
          <p style="text-indent:40px"><span style="font-weight:bold">服务内容：</span></p>
          <div style="width: 33.333333%;float: left;">
          <p style="text-indent:40px">基础运维：</p>
           <p style="text-indent:40px">1、数据中心建设</p>
           <p style="text-indent:40px">2、网络建设</p>
           <p style="text-indent:40px">3、服务器选型、交付和维护</p>
           <p style="text-indent:40px">4、资产管理</p>
           <p style="text-indent:40px">5、基础服务建设</p>
           </div>
          <div style="width: 33.333333%;float: left;">
          <p style="text-indent:40px">系统运维：</p>
          <p style="text-indent:40px">1、设计评审</p>
          <p style="text-indent:40px">2、服务管理</p>
          <p style="text-indent:40px">3、资源管理</p>
          <p style="text-indent:40px">4、预案管理</p>
          <p style="text-indent:40px">5、数据备份</p>
          <p style="text-indent:40px">6、数据库运维</p>
          </div>
          <div style="width: 33.333333%;float: left;">
          <p style="text-indent:40px">业务运维</p>
          <p style="text-indent:40px">1、日常运维</p>
          <p style="text-indent:40px">2、季度巡检</p>
          <p style="text-indent:40px">3、程序运维</p>
          <p style="text-indent:40px">4、程序升级</p>
          <p style="text-indent:40px">5、系统应用</p>
          </div>
          `
                },
                {
                    subtitle: '运营推广',
                    mainBody: `<p style="text-indent:40px"><span style="font-weight:bold">描述：</span>一站式运营推广服务，根据行业定制差异化服务推广方案，搭配火益产品一键运营分发，快速覆盖全网锁定目标客户，并能在线监测效果，及时反馈。步速者注重市场变化，将高科技与新方式相结合，帮助用户精细化的推广，为线上和线下发展带来进一步的突破。</p>

          <p style="text-indent:40px"><span style="font-weight:bold">服务内容：</span>包括网站搭建、微官网搭建、品牌营销、活动策划、内容运营、商城搭建以及运营、数据分析等多项服务。</p>
          
          
          <p style="text-indent:40px"><span style="font-weight:bold"> 服务优势：</span></p>
          <p style="text-indent:40px">1、拥有多场景用户顶级流量</p>
          <p style="text-indent:40px">2、行业定制化优化方案，为用户量身定制差异化解决方案</p>
          <p style="text-indent:40px">3、丰富的运营案例，快速把握互联网市场趋势定制适合用的最佳方案</p>`
                },
                {
                    subtitle: '美术设计',
                    mainBody: `<p style="text-indent:40px"><span style="font-weight:bold">描述：</span>坚持以设计的先导性、实践性和创新的本质服务于客户，着眼文化创新，融合技术与美术，立足于产业特色与项目性质，建构可持续设计创作体系。</p>

          <p style="text-indent:40px"><span style="font-weight:bold">服务内容：</span>根据项目需求和项目背景进行项目UI设计、UE设计、ICON及LOGO设计；深度参与项目移动端开发，融合技术与美术，坚持以最专业的态度，创新打造用户最满意的用户设计体验。</p>          
          <p style="text-indent:40px"><span style="font-weight:bold"> 服务优势：</span></p>
          <p style="text-indent:40px">1、团队成员深耕设计行业数十余年，熟悉Web、IOS、Android设计规范，能根据产品定位、特点及需求，完成高品质的产品创意设计，推动产品整体视觉质量提升；</p>
          <p style="text-indent:40px">2、团队成员均毕业于专业美术院校，美术功底深厚，参与制作项目涉猎多个领域，对高品质美术设计充满热情和追求。</p>
          `
                }
            ],
            solveList: [],
            bgList: {},
        };
    },
    methods: {
        alignCenter() {
            const w = document.body.clientWidth;
            const boxes = $(".cover-name")[0];
            if (boxes) {
                const boxWid = boxes.scrollWidth; //获取div的宽
                boxes.style.left = w / 2 - boxWid / 2 + "px";
            }
        },
        getSoluteData(type) {
            const kind = 1;
            if (type === undefined || type === null) {
                type = 0;
            } else {
                document.getElementById("details").scrollIntoView();
            }
            this.type = type;
            http.get("/industrySolutions/findAllWeb", {
                    params: {
                        type,
                        kind
                    },
                })
                .then((res) => {
                    this.solveList = res.content;
                });
        },
    },
    mounted() {
        const _this = this;
        _this.alignCenter();
        setTimeout(() => {
            let type = sessionStorage.getItem('type');
            _this.getSoluteData(type);
        });
        window.onresize = function() {
            _this.alignCenter();
        };
    },
    beforeDestroy() {
        sessionStorage.removeItem('type');
    },
    computed: {},
    watch: {
        $route() {
            setTimeout(() => {
                let type = sessionStorage.getItem('type');
                this.getSoluteData(type);
            });
        }
    }
};